/**
 * Introducir aquí todas las peticiones a la API de PWGS
 */
import axios from 'axios';
import { ENTORNO_APUYEN } from './../../.env.js';
export class PwgsApi {

    #urlBaseAPI = '';
    #urlAux = '';
    constructor(subdomain = '') {
        this.init(subdomain);
    }

    async init(subdomain) {

        

        if (!subdomain) {
            subdomain = localStorage.getItem('subdomain'); // Intenta recuperar el subdominio de localStorage si no se proporciona
        }

        if (!subdomain) {
            this.#urlBaseAPI = ENTORNO_APUYEN.BaseURL;
        }

        if (subdomain) {
            this.#urlBaseAPI = `https://${subdomain}.pwgs.app/modulos/pwgsapi/index.php/`;   
        }
             
        this.#urlAux = ENTORNO_APUYEN.AuxURL;
    }

    /**
     * 
     * @param {string} path 
     * @param {object} params 
     * @returns 
     */
    async get(path, params) {
        try {
            const baseURL = this.#urlBaseAPI;
            params = params || {};
            if (typeof params !== 'object') {
                throw new Error('Error. Los parámetros han de ser un objeto');
            }
            if (baseURL === '' || 
                (typeof baseURL === 'string' && !baseURL.match(/^https?:\/\/([\w.\-_/]+)$/))) {
                    throw new Error("No se ha podido obtener el dominio de la API. Contacta con soporte para crear el fichero de entorno correspondiente, o actualizarlo con la configuración de dominio de API");
            }
    
            const token = localStorage.getItem('token');
            const queryString = this.#urlEncode(params);
            const url = baseURL + path + (queryString !== '' ? '?' + queryString : '');
    
            // Detecta si el `responseType` se ha definido en los parámetros.
            const responseType = params.responseType || 'json'; // Valor por defecto
    
            const config = {
                params,
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                    "Token": token,
                },
                responseType: responseType,  // Aquí se especifica el tipo de respuesta
                withCredentials: true, // Habilitar envío de cookies
            };
    
            const res = await axios.get(url, config);
    
            if (res.statusText == "Error: Token no válido") {
                const baseURLaux = this.#urlBaseAPI;
                let urlaux = baseURLaux + ('logout');
                axios.get(urlaux, config);
                localStorage.removeItem('token');
                this.$router.push('/login');
            }
    
            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
    
            return res.data;
        } catch (error) {
            console.dir('GET error: ', error);
            if (error.response && error.response.data.codIncidencia) {
                if (error.response.data.descripcionInciencia == "Error: Token no válido") {
                    const baseURLaux = this.#urlBaseAPI;
                    const config = {
                        params,
                        headers: {
                            "Content-Type": "text/plain",
                            "Accept": "*/*",
                            "Token": localStorage.getItem('token'),
                        },
                        withCredentials: true,
                    };
                    let urlaux = baseURLaux + ('logout');
                    axios.get(urlaux, config);
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                throw new Error(error.response.data.descripcionInciencia);
            } else {
                if (error.message == "Error: Token no válido") {
                    const baseURLaux = this.#urlBaseAPI;
                    const config = {
                        params,
                        headers: {
                            "Content-Type": "text/plain",
                            "Accept": "*/*",
                            "Token": localStorage.getItem('token'),
                        },
                        withCredentials: true,
                    };
                    let urlaux = baseURLaux + ('logout');
                    axios.get(urlaux, config);
                    localStorage.removeItem('token');
                    this.$router.push('/login');
                }
                throw new Error(error.message);
            }
        }
    }
    /**
     * 
     * @param {string} path 
     * @param {object} params 
     * @returns 
     */
     async delete(path,params) {
        try {
            const baseURL = this.#urlBaseAPI;
            params = params || {};
            if (typeof params !== 'object') {
                throw new Error('Error. Los parámetros han de ser un objeto');
            }
            if (baseURL === '' || 
                (typeof baseURL === 'string' && !baseURL.match(/^https?:\/\/([\w.\-_/]+)$/))) {
                    throw new Error("No se ha podido obtener el dominio de la API. Contacta con soporte para crear el fichero de entorno correspondiente, o actualizarlo con la configuración de dominio de API");
            }
            const token = localStorage.getItem('token');
            const queryString = this.#urlEncode(params);
            const url = baseURL + path + (queryString !== '' ? '?' + queryString : '');
            const config = {
                params,
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                    "Token": token,
                },
                withCredentials: true, // Habilitar envío de cookies
            };
            const res = await axios.delete(url, config);
            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;
            return data;
        } catch (error) {
            console.dir('GET error: ', error);
            if (error.response && 
                error.response.data.codIncidencia) {
                throw new Error(error.response.data.descripcionInciencia);
            }
            else {
                throw new Error(error.message);
            }
        }
    }
   
      
    /**
     * 
     * @param {Object} obj 
     * @param {string} prefix 
     * @returns string
     */
    #urlEncode(obj, prefix) {
        var str = [],
          p;
        for (p in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
              v = obj[p];
            str.push((v !== null && typeof v === "object") ?
              this.#urlEncode(v, k) :
              encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
        return str.join("&");
    }

    async put(path, body, params) {
        try {
            const baseURL = this.#urlBaseAPI;
            if (baseURL === '' || 
                (typeof baseURL === 'string' && !baseURL.match(/^https?:\/\/([\w.\-_/]+)$/))) {
                    throw new Error("No se ha podido obtener el dominio de la API. Contacta con soporte para crear el fichero de entorno correspondiente, o actualizarlo con la configuración de dominio de API");
            }

            const token = localStorage.getItem('token');
            const queryString = this.#urlEncode(params);
            const url = baseURL + path + (queryString !== '' ? '?' + queryString : '');
            const config = {
                params,
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                    "Token": token,
                },
                withCredentials: true, // Habilitar envío de cookies
            };
            const res = await axios.put(url, body, config);
            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;
            return data;
        } catch (error) {
            console.dir('GET error: ', error);
            if (error.response && 
                error.response.data.codIncidencia) {
                throw new Error(error.response.data.descripcionInciencia);
            }
            else {
                throw new Error(error.message);
            }
        }
    }

    async post(path, body, params) {
        try {
            const baseURL = this.#urlBaseAPI;
            if (baseURL === '' || 
                (typeof baseURL === 'string' && !baseURL.match(/^https?:\/\/([\w.\-_/]+)$/))) {
                    throw new Error("No se ha podido obtener el dominio de la API. Contacta con soporte para crear el fichero de entorno correspondiente, o actualizarlo con la configuración de dominio de API");
            }
            const token = localStorage.getItem('token');
            const queryString = this.#urlEncode(params);
            const url = baseURL + path + (queryString !== '' ? '?' + queryString : '');
            const config = {
                params,
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                    "Token": token,
                },
                withCredentials: true, // Habilitar envío de cookies
            };
            const res = await axios.post(url, body, config);
            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;
            return data;
        } catch (error) {
            console.dir('GET error: ', error);
            if (error.response && 
                error.response.data.codIncidencia) {
                throw new Error(error.response.data.descripcionInciencia);
            }
            else {
                throw new Error(error.message);
            }
        }
    }
    async deletebody(path, body, params) {
        try {
            const baseURL = this.#urlBaseAPI;
            if (baseURL === '' || 
                (typeof baseURL === 'string' && !baseURL.match(/^https?:\/\/([\w.\-_/]+)$/))) {
                    throw new Error("No se ha podido obtener el dominio de la API. Contacta con soporte para crear el fichero de entorno correspondiente, o actualizarlo con la configuración de dominio de API");
            }

            const token = localStorage.getItem('token');
            const queryString = this.#urlEncode(params);
            const url = baseURL + path + (queryString !== '' ? '?' + queryString : '');
            const config = {
                params,
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                    "Token": token,
                },
                withCredentials: true, // Habilitar envío de cookies
                data:body,
            };
            const res = await axios.delete(url,config);
            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;
            return data;
        } catch (error) {
            console.dir('GET error: ', error);
            if (error.response && 
                error.response.data.codIncidencia) {
                throw new Error(error.response.data.descripcionInciencia);
            }
            else {
                throw new Error(error.message);
            }
        }
    }
    // método temporal de autenticación demo
    async login(usuario, password) {
        const url = this.#urlBaseAPI + 'login';
        const datos = {
            Usuario: usuario,
            Clave: password,
        }
        try {
            const res = await axios.post(url, datos, {
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                },
                withCredentials: true, // Habilitar envío de cookies
            });

            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;

           // if (data.codIncidencia !== 0) {
             //   throw new Error(data.descripcionInciencia);
           // }
            return data;
        } catch (error) {
            if (error.response && 
                error.response.data.codIncidencia) {
                throw new Error(error.response.data.descripcionInciencia);
            }
            else {
                throw new Error(error.message);
            }        }
    }
    async loginantiguo(usuario, password) {
        const url = this.#urlAux + '/loginpwgs.php';
        const datos = {
            Usuario: usuario,
            Clave: password,
        }
        try {
            const res = await axios.post(url, datos, {
                headers: {
                    "Content-Type": "text/plain",
                    "Accept": "*/*",
                },
                withCredentials: true, // Habilitar envío de cookies
            });

            if (res.status >= 400) {
                throw new Error(res.statusText);
            }
            const data = res.data;

           // if (data.codIncidencia !== 0) {
             //   throw new Error(data.descripcionInciencia);
           // }
            return data;
        } catch (error) {
            throw new Error(error.message);
        }
    }

    // método temporal de autenticación demo
    async login_old(email, password) {
        const ApiKey = 'AIzaSyAdFNVK2XEzlhy6WpE-CgCvvjpIt8CL_yY';   
        const datos = {
            email,
            password,
            returnSecureToken: true,
        }
        try {
            const res = await axios.post('https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + ApiKey, datos);
            return res.data;
        } catch (error) {
            if (error.response.data.error.message === "EMAIL_NOT_FOUND") {
                throw new Error('El e-mail no se encuentra');
            }
            else if (error.response.data.error.message === "INVALID_PASSWORD") {
                throw new Error('La contraseña es inválida');
            }
            else {
                throw new Error('Error en el login');
            }
        }
    }

} 