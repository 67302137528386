<template>
    <!-- Se encarga de renderizar un menu con submenus -->
    <nav-item v-for="menuitem in menudos" :key="menuitem.nombre" :ruta="menuitem.ruta"
        :clasesNavItem="menuitem.clasesNavItem" :clasesIcono="menuitem.clasesIcono" >
        <!--slot--><span>{{ menuitem.nombre}}</span>
        <!--slot--><i v-if="menuitem.submenu" @click.prevent="toggleSubmenu(menuitem)" class="right fas fa-angle-left"></i>
        <!--slot--><span v-if="menuitem.badge" style="position: relative; left: 130px;" class="badge" :class="['badge-' + menuitem.badge.tipo]">
        <!--slot-->    {{ chats }}
        <!--slot--></span>
        <!-- Parte del submenu, en caso de haberlo -->
        <div v-if="menuitem.submenu && menuitem.submenuvisible" class="submenu">
            <nav-item v-for="submenuitem in menuitem.submenu" :key="submenuitem.nombre" :ruta="submenuitem.ruta"
                :clasesNavItem="submenuitem.clasesNavItem" :clasesIcono="submenuitem.clasesIcono" >
                <span>{{ submenuitem.nombre }}</span>
            </nav-item>
        </div>
    </nav-item>
</template>
<script>
import { PwgsApi } from '../services/PwgsApi';
export default {
    data() {
        return {
            chats:0,
            menudos: [
                {
                    nombre: 'Escritorio / Servicios',
                    ruta: '/escritorio',
                    clasesIcono: 'fas fa-home'
                },
                {
                    nombre: 'Configuración',
                    ruta: '/configuracion',
                    clasesIcono: 'fas fa-cogs',
                    

                },
                {
                    nombre: 'Planning',
                    ruta: '/planning',
                    clasesIcono: 'fas fa-calendar-alt'

                },
                {
                    nombre: 'Chat',
                    ruta: '/chat',
                    clasesIcono: 'fab fa-weixin',
                    badge: {"posicion":'pull-right-container','tipo':'label pull-right bg-purple'}
                },
            ]
        }
    },
    methods:{
        toggleSubmenu(menuitem) {
            // Cambiar la visibilidad del submenú
            menuitem.submenuvisible = !menuitem.submenuvisible;
        },
        async comprobarChats(){
            const api = new PwgsApi();
            var resp = this.chats = await api.get('chat-pwgs');
            this.chats = resp.n_total_registros;
        },
        async comprobar(){
            const api = new PwgsApi();
            let auxusuario = localStorage.getItem('usuario');
            const respplanning = await api.get('parametros-pwgs/planning_geografico');
            const resppermisos = await api.get('usuarios/'+auxusuario+'/permisos');
            var permisos = resppermisos.datos;
            const factencontrado = permisos.find(permiso => permiso.nombre_permiso == "Facturación");
            const adminencontrado= permisos.find(permiso => permiso.nombre_permiso == "Administración");
            
            if(factencontrado || adminencontrado) {
                var informes = 
                {
                    nombre: 'Informes',
                    ruta: '/Informes',
                    clasesIcono: 'fas fa-desktop',
                    submenuvisible:false,
                    submenu:[{
                        nombre: 'Facturación operarios',
                        ruta: '/facturacionOperarios',
                        clasesIcono: 'fas fa-map-marked-alt'
                    }]
                };
                var control = 
                {
                    nombre: 'Control',
                    ruta: '/Control',
                    clasesIcono: 'fas fa-tachometer-alt'

                };
                this.insertarDespuesDePlanning(control);
                this.insertarDespuesDePlanning(informes);
            }
            if(respplanning.planning_geografico == 1){
                var planningGeografico ={
                    nombre: 'Planning Geográfico',
                    ruta: '/planningGeografico',
                    clasesIcono: 'fas fa-map-marked-alt'
                }
                this.insertarDespuesDePlanning(planningGeografico);
            }

        },
        insertarDespuesDePlanning(nuevoItem) {
            const planningIndex = this.menudos.findIndex(item => item.nombre === 'Planning');
            if (planningIndex !== -1) {
                this.menudos.splice(planningIndex + 1, 0, nuevoItem);
            } else {
                // If 'Planning' is not found, add to the end of the array
                this.menudos.push(nuevoItem);
            }
        }
    },
    mounted(){
        this.comprobarChats();
        this.comprobar();
        setInterval(()=>this.comprobarChats(), 60000);
    }
    
}
</script>